import { AuthService } from '~/lib/app/auth'
import { RedirectFn } from '~/lib/app/redirect'
import type { UserCredential } from 'firebase/auth'
import { NotificationStore } from '~/store/notificationStore'
import { Fetcher } from '~/lib/app/fetcher'
import { components } from '~/types/generated/schema'
import { Language } from '~/lib/talentProfileEnums'
import { UserStore } from '~/store/userStore'
import { MessageBus } from '~/lib/app/messageBus/messageBus'
import { AppMessageBus } from '~/lib/app/appState'

export class TalentService {
  constructor(
    private readonly auth: AuthService,
    private readonly fetcher: Fetcher,
    private readonly userStore: UserStore,
    private readonly messageBus: AppMessageBus,
  ) {}

  /**
   * Create a new talent account with the given email and password. The talent will be signed in afterwards.
   */
  async createNewTalent(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    germanLevel: string,
    opportunityTypes: string[],
    acceptedTerms: boolean,
  ): Promise<void> {
    const credential = await this.auth.createUserCredentials(email, password)

    const { user: firebaseUser } = credential
    if (!firebaseUser?.uid) {
      throw new Error('Ein Fehler ist aufgetreten')
    }

    let createUserResponse: components['schemas']['CreateUserResponse']
    try {
      createUserResponse = await this.fetcher.$post('/api/users', {
        firebaseId: firebaseUser.uid,
        email,
      })
    } catch (e: any) {
      await this.auth.deleteCurrentUser()
      throw e
    }

    try {
      await this.fetcher.$post(`/api/v2/talent`, {
        userGuid: createUserResponse.userGuid,
        email,
        firstName,
        lastName,
        phoneNumber,
        opportunityTypes,
        languages: [{ language: Language.German, level: germanLevel }],
        acceptedTerms,
      })
    } catch (e: any) {
      await this.fetcher.$delete(
        `/api/users/${createUserResponse.userGuid}` as `/api/users/{userGuid}`,
        {},
      )
      await this.auth.deleteCurrentUser()
      throw e
    }

    this.messageBus.publish('talent-signed-up', {})

    await this.userStore.refreshData()
  }
}
