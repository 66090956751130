import { components } from '~/types/generated/schema'
import Vue from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { GetResponseContent } from '~/lib/app/apiTypes'
import { Discoverability, MotCallFlow, TalentProfileStatuses } from '~/lib/talentProfileEnums'
import mixpanel from 'mixpanel-browser'
import { RemovableRef, useLocalStorage } from '@vueuse/core'
import { delay } from '~/lib/utils/delay'
import { Fetcher, FetcherError } from '~/lib/app/fetcher'
import { AppMessageBus } from '~/lib/app/appState'
import { AuthService, CurrentUser } from '~/lib/app/auth'
import { Temporal } from '@js-temporal/polyfill'

type TalentData = components['schemas']['TalentInfoDto']
type CompanyData = components['schemas']['GetCompanyResponse']

// Needs to match to UserType in backend
export const enum UserType {
  Talent = 'Talent',
  Recruiter = 'Recruiter',
  HyreAdmin = 'HyreAdmin',
}

export interface UserStoreState {
  userType: UserType | null
  /**
   * Generally this is either the ID of the talent or the recruiter
   */
  externalUserId: string | null
  /**
   * This is the GUID of the actual user entity
   */
  userGuid: string | null
  companyId: string | null
  talentData: TalentData | null
  companyData: DeepRequired<CompanyData> | null

  /**
   * Some info about ourselves when we are impersonating a talent
   */
  impersonator: Impersonator | null
  profileCompleted: boolean | null
  onboardingVideoDismissed: boolean | null

  /**
   * Used to access the firebase id of the talent which is being impersonated,
   * since even when impersonating we are still authenticated as ourselves
   */
  impersonatedTalentInfo: GetResponseContent<`/api/talent/{talentGuid}`> | null
}

interface Impersonator {
  email: string
  guid: string
  firebaseId: string
}

type StoreEventPayload = { store: UserStore }

export class UserStore {
  private isLoading = false
  private impersonateIsRunning = false

  constructor(
    private readonly state: RemovableRef<UserStoreState>,
    private readonly auth: AuthService,
    private readonly fetcher: Fetcher,
    private readonly messageBus: AppMessageBus,
  ) {}

  static get defaultState(): UserStoreState {
    return {
      userType: null,
      externalUserId: null,
      userGuid: null,
      companyId: null,
      talentData: null,
      companyData: null,
      impersonator: null,
      profileCompleted: false,
      onboardingVideoDismissed: false,
      impersonatedTalentInfo: null,
    }
  }

  get userGuid() {
    return this.state.value.userGuid
  }

  get userType() {
    return this.state.value.userType
  }

  get company() {
    if (this.state.value.companyData == null) {
      throw new Error('companyData is null!')
    }

    return new UserStoreCompany(
      this.state.value.companyData,
      this.state.value.externalUserId,
      this.fetcher,
    )
  }

  get companyData() {
    return this.state.value.companyData
  }

  get isCompany() {
    return (
      this.isUserLoggedIn &&
      (this.state.value.userType === UserType.Recruiter ||
        (this.state.value.userType === UserType.HyreAdmin &&
          this.state.value.companyData?.companyName != null))
    )
  }

  get talent() {
    if (this.state.value.talentData == null) return null
    return new UserStoreTalent(this.state.value.talentData)
  }

  get isTalent() {
    return this.isUserLoggedIn && !this.isCompany
  }

  get hasActiveSubscription() {
    return this.state.value.companyData?.subscriptions?.some((sub) => sub.active) ?? false
  }

  get entitlements() {
    return {
      hotlistRequests: this.getNumberEntitlement('hotlist-anfragen'),
      livePositions: this.getNumberEntitlement('live-positions'),
      canViewHotlist: this.getNumberEntitlement('can-view-hotlist') > 0,
    }
  }

  get talentReferralId() {
    return this.state.value.talentData?.referralId
  }

  get companyName() {
    return this.state.value.companyData?.companyName!
  }

  get companyId() {
    return this.state.value.companyId!
  }

  get companyLogoUrl() {
    return this.state.value.companyData?.logoUrl ?? null
  }

  get externalUserId(): string {
    return this.state.value.externalUserId!
  }

  get isAdmin() {
    return this.state.value.userType === UserType.HyreAdmin
  }

  get isImpersonating() {
    return this.impersonator != null
  }

  /**
   * @deprecated this is a bit broken because sometimes there can be talent info stored without having auth. generally due to something going wrong in the logout process
   */
  get isUserLoggedIn() {
    return this.state.value.talentData != null || this.state.value.companyData?.companyName != null
  }

  get talentFullName() {
    const talentData = this.state.value.talentData
    if (!talentData) return ''

    const { firstName, lastName } = talentData
    return `${firstName} ${lastName}`
  }

  get talentFirstName() {
    return (
      this.state.value.talentData?.firstName ??
      this.state.value.companyData?.recruiterFirstName ??
      ''
    )
  }

  get email() {
    return this.state.value.talentData?.email ?? ''
  }

  get profilePicUrl() {
    return this.state.value.talentData?.profilePicUrl ?? ''
  }

  get needsToDoMotCall() {
    return !!this.state.value.talentData?.needsToDoMotCall
  }

  get talentManagerInfo() {
    return this.state.value.talentData?.recruitmentConsultant
  }

  get hasPositionsLive() {
    return this.state.value.companyData?.positions?.some((pos) => pos.isLive) ?? false
  }

  get positions(): components['schemas']['CompanyPositionOverviewItem'][] {
    return this.state.value.companyData?.positions ?? []
  }

  get talentProfileStatus() {
    let profileStatus = this.state.value.talentData?.profileStatus as TalentProfileStatuses

    if (profileStatus == TalentProfileStatuses.Draft) {
      return (sessionStorage.getItem('talentProfileStatus') ??
        profileStatus) as TalentProfileStatuses
    }

    return profileStatus
  }

  get motCallFlow() {
    return this.state.value.talentData?.motCallFlow as MotCallFlow
  }

  get termsAcceptedOn() {
    return Temporal.PlainDate.from(this.state.value.talentData?.termsAcceptedOn || '1970-01-01')
  }

  async setTermsAcceptedOn(newValue: Temporal.PlainDate) {
    // todo call api
    Vue.set(this.state.value.talentData!, 'termsAcceptedOn', newValue.toString())
  }

  get talentProfileCompletionState() {
    return {
      signupCompleted: this.state.value.talentData?.isSignupCompleted,
      cvUploaded: this.state.value.talentData?.cvUploaded,
      searchProfileCompleted: this.state.value.talentData?.searchProfileCompleted,
      salesExperienceCompleted: this.state.value.talentData?.salesExperienceCompleted,
      didJobMatching: this.state.value.talentData?.didJobMatching,
    }
  }

  get profileCompleted() {
    return this.state.value.profileCompleted
  }

  get impersonatedTalentFirebaseId() {
    return this.state.value.impersonatedTalentInfo?.talent?.firebaseId ?? null
  }

  get impersonator() {
    return this.state.value.impersonator
  }

  public static init(auth: AuthService, fetcher: Fetcher, messageBus: AppMessageBus): UserStore {
    return new UserStore(
      useLocalStorage<UserStoreState>('user', UserStore.defaultState, {
        deep: true,
      }),
      auth,
      fetcher,
      messageBus,
    )
  }

  async impersonate(args: { companyGuid?: string; talentGuid?: string }) {
    // if we are currently loading user data then we will try again
    // these methods don't play well together
    while (this.isLoading) {
      await delay(100)
    }

    // Don't wanna run this more than once
    if (this.impersonateIsRunning) return

    this.impersonateIsRunning = true

    try {
      if (this.isImpersonating) {
        throw new Error('already impersonating. you must unimpersonate first!')
      }

      Vue.set(this.state.value, 'impersonator', {
        email: this.email,
        guid: this.externalUserId,
        firebaseId: this.auth.currentUser!.uid,
      })

      if (args.talentGuid) {
        const talentInfo = await this.fetcher.$get<`/api/talent/{talentGuid}`>(
          `/api/talent/${args.talentGuid}` as `/api/talent/{talentGuid}`,
        )
        Vue.set(this.state.value, 'externalUserId', args.talentGuid)
        Vue.set(this.state.value, 'impersonatedTalentInfo', talentInfo)
      } else if (args.companyGuid) {
        Vue.set(this.state.value, 'companyId', args.companyGuid)
        await this.loadCompanyData()
      }

      mixpanel.reset()

      await this.refreshData()
    } finally {
      this.impersonateIsRunning = false
    }
  }

  async unimpersonate() {
    // it can go bad if they spam this or if it triggers while refresh polling is running
    while (this.isLoading) {
      await delay(100)
    }

    if (this.impersonator == null) return

    Vue.set(this.state.value, 'externalUserId', this.impersonator.guid)
    Vue.set(this.state.value, 'companyId', null)
    Vue.set(this.state.value, 'companyData', null)
    Vue.set(this.state.value, 'impersonatedTalentInfo', null)
    Vue.set(this.state.value, 'impersonator', null)

    mixpanel.reset()

    await this.refreshData()
  }

  async refreshData() {
    this.isLoading = true
    try {
      const user = await this.auth.getUser()
      if (user == null) {
        mixpanel.reset()
        if (this.state.value != null) {
          this.reset()
        }
      } else {
        await this.doRefreshData(user)
      }
    } finally {
      this.isLoading = false
    }
  }

  showCalendlyPopup(url: string) {
    window.Calendly.initPopupWidget({
      url,
      prefill: {
        name: this.talentFullName ?? '',
        email: this.email ?? '',
      },
    })
  }

  updateBookedCalendly() {
    Vue.set(this.state.value.talentData!, 'bookedMotCall', 'YES')
    Vue.set(this.state.value.talentData!, 'needsToDoMotCall', false)

    if (this.motCallFlow == MotCallFlow.Mandatory) {
      Vue.set(
        this.state.value.talentData!,
        'profileStatus',
        TalentProfileStatuses.PendingApproval.toString(),
      )
      sessionStorage.setItem(
        'talentProfileStatus',
        TalentProfileStatuses.PendingApproval.toString(),
      )
    }
  }

  setProfilePic(url: string | null) {
    Vue.set(this.state.value.talentData!, 'profilePicUrl', url)
  }

  setProfileCompleted() {
    Vue.set(this.state.value, 'profileCompleted', 'YES')
  }

  reset() {
    mixpanel.reset()
    Vue.set(this.state, 'value', UserStore.defaultState)
  }

  private getNumberEntitlement(featureId: string) {
    return this.state.value
      .companyData!.subscriptions!.flatMap((sub) => sub.entitlements)
      .filter((entitlement) => entitlement!.featureId === featureId)
      .reduce((acc, entitlement) => acc + Number(entitlement!.value ?? 0), 0)
  }

  private async doRefreshData(user: CurrentUser) {
    if (
      !this.state.value.externalUserId ||
      !this.state.value.userType ||
      !this.state.value.userGuid
    ) {
      try {
        const userInfo = await this.fetcher.$get(
          `/api/v2/auth/whoami/${user.uid}` as `/api/v2/auth/whoami/{firebaseId}`,
        )
        this.state.value.userGuid = userInfo.userGuid ?? null
        this.state.value.externalUserId = userInfo.externalId ?? null
        this.state.value.userType = (userInfo.userType as UserType) ?? null
      } catch (e: unknown) {
        console.log(e, (e as FetcherError).status, e instanceof FetcherError)
        if (e instanceof FetcherError && e.status === 404) {
          // during signup, the user will be logged in when they create their credentials
          // at which point we will start trying to load data, but the full signup sequence isn't necessarily finished yet so it isn't possible to load
          console.warn(
            `user with firebase id ${user.uid} not found when loading data (this is ok if we are in signup, otherwise a problem)`,
          )
          return
        }
      }
    }

    // TODO fix this it's weird as hell - we have to first manually call loadCompanyData when we impersonate,
    //      so that in subsequent calls to refresh it will then call loadCompanyData. We should just have a flag or sth
    //      to set the user type so that we know what to call here. also when we impersonate we ended up loading twice which is dumb (not critical)

    if (
      this.userType === UserType.Talent ||
      (this.userType === UserType.HyreAdmin && this.companyData == null)
    ) {
      await this.loadTalentData()
    }

    if (
      this.userType === UserType.Recruiter ||
      (this.userType === UserType.HyreAdmin && this.companyData != null)
    ) {
      await this.loadCompanyData()
    }

    this.messageBus.publish('user-data-loaded', {})
  }

  private async loadTalentData() {
    const talentData = await this.fetcher.$get(
      `/api/v2/talent/${this.state.value.externalUserId}` as `/api/v2/talent/{talentId}`,
    )

    Vue.set(this.state.value, 'talentData', talentData)
  }

  private async loadCompanyData() {
    if (this.state.value.companyId == null) {
      const [searchResult] = await this.fetcher.$get(`/api/v2/company`, {
        query: { recruiterId: this.state.value.externalUserId! },
      })
      Vue.set(this.state.value, 'companyId', searchResult.externalId!)
    }
    const companyData = await this.fetcher.$get(
      `/api/v2/company/${this.state.value.companyId}` as `/api/v2/company/{companyId}`,
    )
    Vue.set(this.state.value, 'companyData', companyData)
  }
}

class UserStoreCompany {
  constructor(
    private readonly companyData: NonNullable<UserStoreState['companyData']>,
    private userId: string | null,
    private readonly fetcher: Fetcher,
  ) {}

  get csManagerEmail() {
    return this.companyData.csManager?.csManagerEmail
  }

  get postOnboardingCallBookingUrl() {
    return this.companyData.postOnboardingCallBookingUrl
  }

  get currentSubscription() {
    return this.companyData.subscriptions?.find((sub) => sub.active)
  }

  get isOnboardingComplete() {
    return this.companyData?.isOnboardingComplete
  }

  get didVisitHotlist() {
    return this.companyData?.onboardingInfo?.didVisitHotlist
  }

  get firsJobDone() {
    return this.companyData.positions?.some((x) => x.isSubmitted)
  }

  getPositionBySlug(positionSlug: string) {
    return this.findPosition((pos) => pos.positionSlug === positionSlug)
  }

  getPositionByName(positionName: string) {
    return this.findPosition((pos) => pos.positionName === positionName)
  }

  async updateRecruiterOnboarding(data: components['schemas']['UpdateOnboardingRequestData']) {
    await this.fetcher.$post(
      `/api/recruiters/${this.userId}/onboarding` as `/api/recruiters/{recruiterGuid}/onboarding`,
      data,
    )
    Vue.set(this.companyData!.onboardingInfo, 'didVisitHotlist', true)
  }

  private findPosition(
    predicate: (pos: components['schemas']['CompanyPositionOverviewItem']) => boolean,
  ) {
    return this.companyData.positions?.find(predicate)
  }
}

class UserStoreTalent {
  constructor(private readonly talentData: Readonly<TalentData>) {}

  // refers to existing talents from before the new talent signup flow

  get profileStatus() {
    return this.talentData.profileStatus as TalentProfileStatuses
  }

  get isHotlistActive() {
    return this.talentData.discoverableByCompanies === Discoverability.Yes
  }

  get signupDate(): Temporal.PlainDate {
    return Temporal.PlainDate.from(this.talentData.signupDate!)
  }

  get didAgreeToCompanyChange() {
    return !!this.talentData.didAgreeToCompanyChange
  }

  setDidAgreeToCompanyChange() {
    Vue.set(this.talentData, 'didAgreeToCompanyChange', true)
  }
}

export const useUserStore = () => {
  const ctx = useContext()

  return ctx.$user
}
