import mixpanel, {
  Callback,
  Dict,
  OverridedMixpanel,
  People,
  RegisterOptions,
  RequestOptions,
} from 'mixpanel-browser'

export interface Analytics {
  track(
    // TODO limit this to just AnalyticsEvent
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
  ): void
  register(props: Dict, days_or_options?: number | Partial<RegisterOptions>): void
  people: People
  track_pageview(properties?: Dict, options?: { event_name?: string | undefined }): void
}

export enum AnalyticsEvent {
  TalentSubmittedSignupKickoutQuestions = 'Talent Submitted Signup Kickout Questions',
  TalentCompletedSignup = 'Talent Completed Sign Up',
  ErrorOccurredDuringTalentSignup = 'Error Occurred During Talent Signup',
  SignupEmailWasInUse = 'Signup Email Was In Use',
  TalentSubmittedSignupDetails = 'Talent Submitted Signup Details',
  NewTalentEnteredFirstName = 'New Talent Entered First Name',
  NewTalentEnteredLastName = 'New Talent Entered Last Name',
  NewTalentEnteredPhoneNumber = 'New Talent Entered Phone Number',
  NewTalentEnteredEmail = 'New Talent Entered Email',
  NewTalentEnteredPassword = 'New Talent Entered Password',
  NewTalentToggledTermsCheckbox = 'New Talent Toggled Terms Checkbox',
}

export async function initializeAnalytics(
  mixpanelId: string,
  debug: boolean,
  recordSessionsPercent: number | undefined,
): Promise<Analytics> {
  await new Promise((resolve) => {
    mixpanel.init(mixpanelId, {
      debug,
      persistence: 'localStorage',
      loaded: () => resolve(undefined),
      record_sessions_percent: recordSessionsPercent,
      record_mask_text_selector: '.mxp-mask',
    })
  })

  return mixpanel
}
