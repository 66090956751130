import { Context } from '@nuxt/types'

/**
 * Stop unauthenticated users from access protected pages (all pages except jobs board and hotlist candidate profiles)
 */
export default async function goToAppIfLoggedIn({ $app: { auth }, redirect }: Context) {
  const hasAuth = (await auth.getUser()) != null
  if (hasAuth) {
    redirect('/')
  }
}
