import { UserStore } from '~/store/userStore'
import { Fetcher } from '~/lib/app/fetcher'

export interface CompanySubscriptionService {
  loadCheckoutPortalUrl(redirectPath: string): Promise<string>
}

export class CompanySubscriptionServiceImpl implements CompanySubscriptionService {
  constructor(private readonly user: UserStore, private readonly fetcher: Fetcher) {}

  async loadCheckoutPortalUrl(redirectPath: string): Promise<string> {
    if (!this.user.isUserLoggedIn || !this.user.isCompany) {
      throw new Error('not-a-company')
    }

    await this.user.refreshData()

    if (this.user.hasActiveSubscription) {
      throw new Error('subscription-exists')
    }

    // in the backend subscriptions are being saved via webhooks from chargebee which
    // means if we need them _straight away_ it doesnt quite work
    // /company/:companyGuid/subscriptions endpoint reads from CB directly, so if the subscription was just created we will get it
    // we should stop playing around with chargebee webhooks for storing subscription data, and just read it from the API directly (and maybe cache it optionally idk)
    // anyway this is a backend concern so ill say no more
    // its all a bit off a mess
    const { subscriptions } = await this.fetcher.$get(
      `/api/company/${this.user.companyId}/subscriptions` as `/api/company/{companyGuid}/subscriptions`,
    )

    if (subscriptions?.some((sub) => sub.status === 'Active')) {
      throw new Error('subscription-exists')
    }

    const portal = await this.fetcher.$get(
      `/api/v2/company/${this.user.companyId}/checkout-portal-url` as `/api/v2/company/{companyId}/checkout-portal-url`,
      { query: { redirectUrl: `${location.protocol}//${location.host}${redirectPath}` } },
    )

    return portal.url!
  }
}
