import { Context } from '@nuxt/types'

export default async function forceRecruiterToChargebeeCheckout({
  $app: { user, fetcher },
  redirect,
}: Context) {
  if (!user.isCompany) {
    return
  }

  if (user.hasActiveSubscription) {
    return
  }

  await user.refreshData()

  // in the backend subscriptions are being saved via webhooks from chargebee which
  // means if we need them _straight away_ it doesnt quite work
  // /company/:companyGuid/subscriptions endpoint reads from CB directly, so if the subscription was just created we will get it
  // we should stop playing around with chargebee webhooks for storing subscription data, and just read it from the API directly (and maybe cache it optionally idk)
  // anyway this is a backend concern so ill say no more
  // its all a bit off a mess
  const { subscriptions } = await fetcher.$get(
    `/api/company/${user.companyId}/subscriptions` as `/api/company/{companyGuid}/subscriptions`,
  )

  // recruiters who have ever had a subscription are allowed
  // otherwise they need to buy
  if (subscriptions && subscriptions.length > 0) {
    return
  }

  let portalUrl = null
  try {
    const redirectPath = user.company.isOnboardingComplete ? '/' : '/company/onboarding'
    const redirectUrl = `${location.protocol}//${location.host}${redirectPath}?expectSubscription=true&skipPayment=true`
    const portal = await fetcher.$get(
      `/api/v2/company/${user.companyId}/checkout-portal-url` as `/api/v2/company/{companyId}/checkout-portal-url`,
      { query: { redirectUrl } },
    )

    portalUrl = portal.url
  } catch (e: any) {
    console.error(e.response?.data?.detail, e)
    redirect('/error', {
      message:
        'Etwas ist schiefgelaufen. Bitte kontaktiere uns unter customer.success@hyretalents.com.' +
        ` Fehlercode: ${e.response?.data?.detail}`,
    })
  }

  // for some weird reason
  // at least in development
  // this throws but still works
  //
  // so because of this, we call redirect outside of the try so that the client isn't flashed an "Error :(" screen
  if (portalUrl) redirect(portalUrl)
}
