import { Context } from '@nuxt/types'

/**
 * Stop unauthenticated users from access protected pages (all pages except jobs board and hotlist candidate profiles)
 */
export default async function ensureUserAuth({ $app: { auth, user }, redirect, route }: Context) {
  const currentUser = await auth.getUser()
  const allowedRoutes = ['jobs', 'jobs-id', 'hotlist-candidates-candidateId']

  if (currentUser != null) {
    return
  }

  if (allowedRoutes.includes(route.name ?? '')) {
    return
  }

  user.reset()

  redirect(`/logout`, {
    redirect: route.fullPath,
  })
}
