var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"bg-primary-500 border-2 border-primary-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-center rounded-full cursor-pointer w-full sm:w-fit",class:{
    'sm:min-w-32 px-4 sm:px-6 min-h-16 h-9': !_vm.small,
    'sm:min-w-32 px-4 sm:px-6 min-h-8 h-4': _vm.small,
  },attrs:{"type":_vm.type},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"h-full w-full font-roc flex items-center justify-center text-white",class:{
      'text-lg tracking-wide font-medium': !_vm.small,
      'text-sm tracking-sm': _vm.small,
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }